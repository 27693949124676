import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core'
import { endOfToday, startOfYesterday } from '../../../utils/time-utils'

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'D MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@Component({
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DateRangeSelectorComponent implements OnInit, OnChanges {
  @Input()
  from: number
  @Input()
  to: number

  @Output()
  rangeChange = new EventEmitter<DateRange>()
  range: DateRange

  readonly TODAY = new Date(endOfToday())
  fromControl: UntypedFormControl
  toControl: UntypedFormControl

  constructor() {}

  ngOnInit() {
    const from = this.from || startOfYesterday()
    const to = this.to || endOfToday()
    this.fromControl = new UntypedFormControl(new Date(from))
    this.toControl = new UntypedFormControl(new Date(to))

    this.from = from
    this.to = to
    this.range = {
      from,
      fromDate: new Date(from),
      to,
      toDate: new Date(to),
    }

    this.fromControl.valueChanges.subscribe((value: Date | null) => {
      if (value === null || value.valueOf() === this.from) {
        return
      }
      this.updateValue('from', value)
      this.rangeChange.emit(this.range)
    })

    this.toControl.valueChanges.subscribe((value: Date) => {
      if (value === null || value.valueOf() === this.to) {
        return
      }
      this.updateValue('to', value)
      this.rangeChange.emit(this.range)
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.range && (changes.from || changes.to)) {
      const from = changes.from ? changes.from.currentValue : null
      const to = changes.to ? changes.to.currentValue : null
      this.updateRange(from, to)
    }
  }

  private updateRange(from: Date | number | null, to: Date | number | null) {
    if (from) {
      this.updateValue('from', from)
    }
    if (to) {
      this.updateValue('to', to)
    }
  }

  private updateValue(key: string, value: Date | number) {
    if (value instanceof Date) {
      this.range[`${key}Date`] = value
      this[key] = this.range[key] = value.getTime()
    } else {
      if (value === this.range[key]) {
        return
      }
      this[key] = this.range[key] = value
      this.range[`${key}Date`] = new Date(value)
    }
  }
}

export interface DateRange {
  from: number
  fromDate: Date
  to: number
  toDate: Date
}
