import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table'
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip'
import { BaseModule } from 'shared/base/base.module'
import { SharedBrandModule } from '../shared-brand/shared-brand.module'
import { DateRangeSelectorComponent } from './date-range-selector/date-range-selector.component'
import { FilterMenuComponent } from './filter-menu/filter-menu.component'
import { FiltersContainerComponent } from './filters/filters-container.component'
import { FlatTableComponent } from './flat-table/flat-table.component'
import { HeadingFiltersComponent } from './heading-filters/heading-filters.component'
import { PaginatorComponent } from './paginator/paginator.component'
import { SearchBoxComponent } from './search-box/search-box.component'
import { SelectorComponent } from './selector/selector.component'
import { FilterService } from './services/filter.service'
import { SortMenuComponent } from './sort-menu/sort-menu.component'

@NgModule({
  declarations: [
    DateRangeSelectorComponent,
    FilterMenuComponent,
    FiltersContainerComponent,
    FlatTableComponent,
    HeadingFiltersComponent,
    PaginatorComponent,
    SearchBoxComponent,
    SelectorComponent,
    SortMenuComponent,
  ],
  exports: [
    DateRangeSelectorComponent,
    FilterMenuComponent,
    FiltersContainerComponent,
    FlatTableComponent,
    HeadingFiltersComponent,
    PaginatorComponent,
    SearchBoxComponent,
    SelectorComponent,
    SortMenuComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    SharedBrandModule,
    BaseModule,
  ],
  providers: [FilterService],
})
export class SharedDataModule {}
